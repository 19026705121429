<template>
  <a @click="flipCard" class="bg-white shadow-lg rounded-xl overflow-hidden cursor-pointer hover:shadow-2xl transition duration-200" :content="title" v-tippy>
    <div v-show="!flipped">
      <div>
        <img v-if="loading || !image" src="../assets/placeholder.png" title="Gorgon Medusa Placeholder"/>
        <img v-else :src="image"/>
      </div>
    </div>
    <div v-show="flipped" class="p-4 text-center">
      <ul>
        <li v-for="(attribute, index) in attributes" :key="index" class="my-5">
          <div class="flex items-center">
            <small class="mr-3">{{ attribute.trait_type }}:</small>
            <small :class="`flex-1 text-right ${getRarityColor($options.stats[attribute.trait_type][attribute.value].rarity)}`">{{ attribute.value }}</small>
          </div>
        </li>
      </ul>
    </div>
  </a>
</template>

<script>
import LocalStorage from '../mixins/LocalStorage.vue';
import {Buffer} from 'buffer';
import axios from 'axios';
import Rarity from '../mixins/Rarity.vue';

export default {
  async created() {
    try {
      // else, fetch them
      const {data} = await axios.get(`api/metadata/${this.token}`);
      const {name, signature, attributes, image} = data;
      // and finally update data
      this.updateMetaData(name, signature, attributes, image);
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  },
  props: {
    token: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      attributes: [],
      name: '',
      signature: '',
      image: '',
      flipped: false,
      loading: true
    }
  },
  computed: {
    title() {
      return `Gorgon #${this.token}`;
    }
  },
  methods: {
    async getBase64(url) {
      const {data} = await axios.get(url, {
        responseType: 'arraybuffer'
      });
      const content = Buffer.from(data, 'binary').toString('base64');
      return `data:image/png;base64,${content}`;
    },
    updateMetaData(name, signature, attributes, image) {
      this.attributes = [...attributes];
      this.name = name;
      this.signature = signature;
      this.image = this.getImageOrigin(image);
    },
    getImageOrigin(image) {
      if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
        let parts = image.split('api/');
        return `${window.location.origin}/api/${parts[1]}`;
      }
      return image;
    },
    flipCard() {
      if (!this.loading && this.image) {
        this.flipped = !this.flipped;
      }
    }
  },
  mixins: [LocalStorage, Rarity]
}
</script>

<style scoped>
  [data-placeholder]::after {
    content: " ";
    box-shadow: 0 0 50px 9px rgba(254,254,254);
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%; 
    animation: load 1s infinite;
  }
  @keyframes load {
    0%{ left: -100%}
    100%{ left: 150%}
  }
</style>