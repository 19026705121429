<template>
  <div>
    <form @submit.prevent="search">
      <div class="flex lg:w-1/2 sm:w-auto mt-10 mx-auto">
        <div class="flex-1 mr-6">
          <input-text
            placeholder="Enter your address"
            v-model="ownerWalletValue"
          />
        </div>
        <action-button :loading="lair_loading">Search Lair</action-button>
      </div>
    </form>
    <div v-if="wallet_items.length" class="mt-10">
      <div class="flex">
        <div class="flex-1">
          <div class="flex flex-row items-start grid grid-flow-row lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-7">
            <gorgon-card
              v-for="token in paginatedItems"
              :key="token"
              :token="token"
            />
          </div>
        </div>
      </div>
      <div class="my-10 text-center">
        <pagination-links :items="wallet_items" :page-size="10" :max-pages="5" @changePage="onChangePage"/>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '../components/InputText.vue';
import ActionButton from '../components/ActionButton.vue';
import GorgonCard from '../components/GorgonCard.vue';
import PaginationLinks from '../components/PaginationLinks.vue';
import {mapState, mapActions, mapMutations} from 'vuex';

export default {
  components: {
    InputText,
    ActionButton,
    GorgonCard,
    PaginationLinks
  },
  data() {
    return {
      paginatedItems: []
    }
  },
  computed: {
    ...mapState(['owner_wallet', 'wallet_items', 'lair_loading']),
    ownerWalletValue: {
      get() {
        return this.owner_wallet;
      },
      set(value) {
        this.updateOwnerWallet(value);
      }
    }
  },
  methods: {
    ...mapMutations(['updateOwnerWallet']),
    ...mapActions(['searchOwner']),
    async search() {
      try {
        if (this.ownerWalletValue.length) {
          await this.searchOwner(this.ownerWalletValue)
        }
      } catch (error) {
        console.log(error);
      }
    },
    onChangePage(paginatedItems) {
      this.paginatedItems = paginatedItems;
    }
  }
}
</script>