<template>
  <ul v-if="pager.pages && pager.pages.length" :style="ulStyles">
    <li class="inline-block">
      <action-button
        :disabled="pager.currentPage === 1"
        @click="setPage(1)"
      >{{labels.first}}</action-button>
    </li>
    <li class="inline-block mx-2">
      <action-button
        :disabled="pager.currentPage === 1"
        @click="setPage(pager.currentPage - 1)"
      >{{labels.previous}}</action-button>
    </li>
    <li v-for="page in pager.pages" :key="page" class="inline-block mx-2">
      <action-button
        :disabled="pager.currentPage === page"
        :transparent="pager.currentPage !== page"
        @click="setPage(page)"
      >{{page}}</action-button>
    </li>
    <li class="inline-block mx-2">
      <action-button
        :disabled="pager.currentPage === pager.totalPages"
        @click="setPage(pager.currentPage + 1)"
      >{{labels.next}}</action-button>
    </li>
    <li class="inline-block">
      <action-button
        :disabled="pager.currentPage === pager.totalPages"
        @click="setPage(pager.totalPages)"
      >{{labels.last}}</action-button>
    </li>
  </ul>
</template>

<script>
import paginate from 'jw-paginate';
import ActionButton from './ActionButton.vue';

const defaultLabels = {
    first: 'First',
    last: 'Last',
    previous: 'Previous',
    next: 'Next'
};

const defaultStyles = {
  ul: {
    margin: 0,
    padding: 0,
    display: 'inline-block'
  },
  li: {
    listStyle: 'none',
    display: 'inline',
    textAlign: 'center'
  },
  a: {
    cursor: 'pointer',
    padding: '6px 12px',
    display: 'block',
    float: 'left'
  }
};

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    initialPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    maxPages: {
      type: Number,
      default: 10
    },
    labels: {
      type: Object,
      default: () => defaultLabels
    },
    styles: {
      type: Object
    },
    disableDefaultStyles: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ActionButton
  },
  data () {
    return {
      pager: {},
      ulStyles: {},
      liStyles: {},
      aStyles: {}
    }
  },
  created () {
    if (!this.$listeners.changePage) {
      throw 'Missing required event listener: "changePage"';
    }

    // set default styles unless disabled
    if (!this.disableDefaultStyles) {
      this.ulStyles = defaultStyles.ul;
      this.liStyles = defaultStyles.li;
      this.aStyles = defaultStyles.a;
    }

    // merge custom styles with default styles
    if (this.styles) {
      this.ulStyles = { ...this.ulStyles, ...this.styles.ul };
      this.liStyles = { ...this.liStyles, ...this.styles.li };
      this.aStyles = { ...this.aStyles, ...this.styles.a };
    }

    // set page if items array isn't empty
    if (this.items && this.items.length) {
      this.setPage(this.initialPage);
    }
  },
  methods: {
    setPage(page) {
      const { items, pageSize, maxPages } = this;

      // get new pager object for specified page
      const pager = paginate(items.length, page, pageSize, maxPages);

      // get new page of items from items array
      const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

      // update pager
      this.pager = pager;

      // emit change page event to parent component
      this.$emit('changePage', pageOfItems);
    }
  },
  watch: {
    items: function() {
      this.setPage(this.initialPage);
    }
  }
}
</script>